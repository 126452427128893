import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, CardBody, CardTitle, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import { graphql } from "gatsby"
import { HomepageImage, SectionMore } from "../../../../components/ui"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

interface Section6Card {
  id: string
  image1: HomepageImage
  image2: HomepageImage
  title: string
  description: string
}

export interface SubServiceSection6Props {
  id: string
  title: string
  section6Card: Section6Card[]
  section6More: SectionMore[]
  background: HomepageImage
}

const SubServiceSection6 = memo((props: SubServiceSection6Props) => {
  return (
    <div id="section6" className="section pt-0 pb-0" id="subServiceSection6">
      <BackgroundImage
        className="team-3 section-image pt-0"
        {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
      >
        <Col
          className={clsx(
            "ml-auto mr-auto text-center",
            classes.titleSec4Wrapper
          )}
        >
          <h2 className={clsx("title", classes.titleSec4)}>{props?.title}</h2>
        </Col>

        <Container className="mt-5 mb-5">
          <Row className={clsx("mb-5", classes.row)}>
            <Col className="ml-auto mr-auto">
              <Card className="card-profile my-0">
                <Row>
                  <Col md="6">
                    <div className="card-image d-flex flex-column justify-content-center align-items-center h-100">
                      <GatsbyImage
                        className={clsx("rounded", classes.cardImageSec6)}
                        alt={props?.section6Card[0]?.image1?.alt}
                        image={getImage(
                          props?.section6Card[0]?.image1?.gatsbyImageData
                        )}
                      />
                      <GatsbyImage
                        className={clsx("rounded", classes.cardImageSec6)}
                        alt={props?.section6Card[0]?.image2?.alt}
                        image={getImage(
                          props?.section6Card[0]?.image2?.gatsbyImageData
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <CardBody>
                      <CardTitle tag="h3" className="info-title">
                        {props?.section6Card[0]?.title}
                      </CardTitle>
                      <p className="card-description">
                        {props?.section6Card[0]?.description}
                      </p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className={clsx(classes.row)}>
            <Col className="ml-auto mr-auto">
              <Card className="card-profile my-0">
                <Row>
                  <Col md="6">
                    <div className="card-image">
                      <GatsbyImage
                        className={clsx("rounded", classes.cardImageSec6b)}
                        alt={props?.section6Card[1]?.image1?.alt}
                        image={getImage(
                          props?.section6Card[1]?.image1?.gatsbyImageData
                        )}
                      />
                      <GatsbyImage
                        className={clsx("rounded", classes.cardImageSec6b)}
                        alt={props?.section6Card[1]?.image2?.alt}
                        image={getImage(
                          props?.section6Card[1]?.image2?.gatsbyImageData
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <CardBody>
                      <CardTitle tag="h3" className="info-title">
                        {props?.section6Card[1]?.title}
                      </CardTitle>
                      <p className="card-description">
                        {props?.section6Card[1]?.description}
                      </p>
                    </CardBody>
                  </Col>
                </Row>
                
              </Card>
              <Button className="px-5 " color="primary" href="/enquiry">
          <h4 className="m-0">Enquiry</h4>
        </Button>
            </Col>
          </Row> 
        </Container>

        <RelatedSearch listItem={props?.section6More} />
      </BackgroundImage>
    </div>
  )
})

export default SubServiceSection6

export const query = graphql`
  fragment SubServiceSection6Content on SubServiceSection6 {
    id
    title
    section6Card {
      id
      image1 {
        alt
        id
        gatsbyImageData
      }
      image2 {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section6More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
