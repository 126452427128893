import React, { memo } from "react"
import * as sections from "./components/sections"
import Fallback from "../../components/fallback"

interface Props {
  blocks: sections.SubServiceBlock[]
}

const SubServiceContainer = memo((props: Props) => {
  const { blocks } = props

  return (
    <div className="wrapper">
      {blocks?.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...(componentProps as any)} />
      })}
    </div>
  )
})

export default SubServiceContainer
