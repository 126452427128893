import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import { graphql } from "gatsby"
import {
  HomepageImage,
  SectionCard,
  SectionMore,
} from "../../../../components/ui"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

export interface SubServiceSection3Props {
  id: string
  title: string
  description: string
  section3Card: SectionCard[]
  section3More: SectionMore[]
  background: HomepageImage
}

const SubServiceSection3 = memo((props: SubServiceSection3Props) => {
  return (
    <div className="section pt-0 pb-0" id="subServiceSection3">
      <BackgroundImage
        className="team-3 section-image pt-0"
        {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
      >
        <Col
          className={clsx(
            "ml-auto mr-auto text-center",
            classes.titleSec4Wrapper
          )}
        >
          <h2 className={clsx("title pb-0", classes.titleSec4)}>
            {props?.title}
          </h2>
          <h4 className={clsx("description", classes.subTitleSec4)}>
            {props?.description}
          </h4>
        </Col>

        <Container className="mt-5 mb-3">
          <Row
            className={clsx(
              "justify-content-center align-items-center",
              classes.row
            )}
          >
            <Col
              lg="5"
              className={clsx("ml-auto mr-auto", classes.cardContainer)}
            >
              <Card className="mb-0">
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section3Card[0]?.image?.alt}
                    image={getImage(
                      props?.section3Card[0]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center", classes.cardContent)}>
                  <h4 className="info-title">
                    {props?.section3Card[0]?.title}
                  </h4>
                  <p className="description">
                    {props?.section3Card[0]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="2" />
            <Col
              lg="5"
              className={clsx("ml-auto mr-auto", classes.cardContainer)}
            >
              <Card className="mb-0">
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section3Card[1]?.image?.alt}
                    image={getImage(
                      props?.section3Card[1]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center", classes.cardContent)}>
                  <h4 className="info-title">
                    {props?.section3Card[1]?.title}
                  </h4>
                  <p className="description">
                    {props?.section3Card[1]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>

        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button className="px-5 " color="primary" href="https://smartcctv.nz">
            <h4 className="m-0">See Our Sister Company</h4>
          </Button>
        </div>

        <RelatedSearch listItem={props?.section3More} />
      </BackgroundImage>
    </div>
  )
})

export default SubServiceSection3

export const query = graphql`
  fragment SubServiceSection3Content on SubServiceSection3 {
    id
    title
    description
    section3Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section3More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
