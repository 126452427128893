import React, { memo } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Card, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import { SectionCard, SectionMore } from "../../../../components/ui"
import { graphql } from "gatsby"

export interface SubServiceSection4Props {
  id: string
  title: string
  description: string
  heading: string
  section4Card: SectionCard[]
  section4More: SectionMore[]
}

const SubServiceSection4 = memo((props: SubServiceSection4Props) => {
  return (
    <div  className={classes.subSection4Background} id="subServiceSection4">
      <Row className={classes.titleSec3Wrapper}>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2
            className={clsx("title", classes.titleSec3, classes.uppercaseWord)}
          >
            {props?.title}
          </h2>
          <h4
            className={clsx("category text-muted mb-3", classes.subTitleSec3)}
          >
            {props?.description}
          </h4>
        </Col>
      </Row>

      <Container className="mt-4">
        {/* <Row>
          <Col className="ml-auto mr-auto text-center" md="12">
            <h5 className={clsx("text-muted", classes.subTitleSec3)}>
              {props?.heading}
            </h5>
          </Col>
        </Row> */}

        <div className={clsx("section-story-overview", classes.row)}>
          <Row>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[0]?.image?.alt}
                    image={getImage(
                      props?.section4Card[0]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[0]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[0]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[1]?.image?.alt}
                    image={getImage(
                      props?.section4Card[1]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[1]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[1]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[2]?.image?.alt}
                    image={getImage(
                      props?.section4Card[2]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[2]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[2]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div className={clsx("section-story-overview", classes.row)}>
          <Row>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[3]?.image?.alt}
                    image={getImage(
                      props?.section4Card[3]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[3]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[3]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[4]?.image?.alt}
                    image={getImage(
                      props?.section4Card[4]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[4]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[4]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[5]?.image?.alt}
                    image={getImage(
                      props?.section4Card[5]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[5]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[5]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div className={clsx("section-story-overview pb-4", classes.row)}>
          <Row>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[6]?.image?.alt}
                    image={getImage(
                      props?.section4Card[6]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[6]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[6]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[7]?.image?.alt}
                    image={getImage(
                      props?.section4Card[7]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[7]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[7]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg="4" className="text-center">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[8]?.image?.alt}
                    image={getImage(
                      props?.section4Card[8]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[8]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[8]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <div className="d-flex justify-content-center align-items-center mb-0">
        <Button className="px-5 " color="primary" href="/enquiry">
          <h4 className="m-0">Enquiry</h4>
        </Button>
      </div>

      
    </div>
  )
})

export default SubServiceSection4

export const query = graphql`
  fragment SubServiceSection4Content on SubServiceSection4 {
    id
    title
    description
    heading
    section4Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section4More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
  }
`
