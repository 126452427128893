import React, { memo } from "react"
import Layout from "../components/Layout/index"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import SubServiceContainer from "../containers/SubService"
import * as sections from "../containers/SubService/components/sections"

interface Props {
  data: {
    subService: {
      id: string
      title: string
      image: { id: string; url: string }
      blocks: sections.SubServiceBlock[]
    }
  }
}

const SubService = memo((props: Props) => {
  const { subService } = props.data

  return (
    <Layout title="Sub Services">
      <SEO title="Sub Services" />
      <SubServiceContainer blocks={subService?.blocks} />
    </Layout>
  )
})

export default SubService

export const query = graphql`
  {
    subService {
      id
      title
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...SubServiceSection1Content
        ...SubServiceSection2Content
        ...SubServiceSection3Content
        ...SubServiceSection4Content
        ...SubServiceSection5Content
        ...SubServiceSection6Content
      }
    }
  }
`
