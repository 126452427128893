import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import { graphql } from "gatsby"
import {
  HomepageImage,
  SectionCard,
  SectionMore,
} from "../../../../components/ui"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

export interface SubServiceSection5Props {
  id: string
  title: string
  section5Card: SectionCard[]
  section5More: SectionMore[]
  background: HomepageImage
}

const SubServiceSection5 = memo((props: SubServiceSection5Props) => {
  return (
    <div className="section pt-0 pb-0" id="subServiceSection5">
      <BackgroundImage
        className="team-3 section-image pt-0"
        {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
      >
        <Col
          className={clsx(
            "ml-auto mr-auto text-center",
            classes.titleSec4Wrapper
          )}
        >
          <h2 className={clsx("title", classes.titleSec4)}>{props?.title}</h2>
        </Col>

        <Container className="mt-5">
          <div className={clsx("section-story-overview", classes.row)}>
            <Row>
              <Col lg="4" className="text-center">
                <Card className={clsx("mb-0", classes.cardSec3)}>
                  <div className="card-image">
                    <GatsbyImage
                      className={clsx("rounded", classes.cardImage)}
                      alt={props?.section5Card[0]?.image?.alt}
                      image={getImage(
                        props?.section5Card[0]?.image?.gatsbyImageData
                      )}
                    />
                  </div>
                  <div
                    className={clsx("info text-center", classes.cardContent)}
                  >
                    <h4 className="info-title">
                      {props?.section5Card[0]?.title}
                    </h4>
                    <p className="description mb-0">
                      {props?.section5Card[0]?.description}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col lg="4" className="text-center">
                <Card className={clsx("mb-0", classes.cardSec3)}>
                  <div className="card-image">
                    <GatsbyImage
                      className={clsx("rounded", classes.cardImage)}
                      alt={props?.section5Card[1]?.image?.alt}
                      image={getImage(
                        props?.section5Card[1]?.image?.gatsbyImageData
                      )}
                    />
                  </div>
                  <div
                    className={clsx("info text-center", classes.cardContent)}
                  >
                    <h4 className="info-title">
                      {props?.section5Card[1]?.title}
                    </h4>
                    <p className="description mb-0">
                      {props?.section5Card[1]?.description}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col lg="4" className="text-center">
                <Card className={clsx("mb-0", classes.cardSec3)}>
                  <div className="card-image">
                    <GatsbyImage
                      className={clsx("rounded", classes.cardImage)}
                      alt={props?.section5Card[2]?.image?.alt}
                      image={getImage(
                        props?.section5Card[2]?.image?.gatsbyImageData
                      )}
                    />
                  </div>
                  <div
                    className={clsx("info text-center", classes.cardContent)}
                  >
                    <h4 className="info-title">
                      {props?.section5Card[2]?.title}
                    </h4>
                    <p className="description mb-0">
                      {props?.section5Card[2]?.description}
                    </p>
                  </div>
                </Card>
                
              </Col>
              <Button className="px-5 " color="primary" href="/enquiry">
          <h4 className="m-0">Enquiry</h4>
        </Button>
            </Row>
            
          </div>
          
        </Container>

        <RelatedSearch listItem={props?.section5More} />
      </BackgroundImage>
    </div>
  )
})

export default SubServiceSection5

export const query = graphql`
  fragment SubServiceSection5Content on SubServiceSection5 {
    id
    title
    section5Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section5More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
