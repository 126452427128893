// extracted by mini-css-extract-plugin
export var avatar = "styles-module--avatar--1Qi49";
export var card = "styles-module--card--w84+b";
export var cardBodyItem = "styles-module--cardBodyItem--Mmnk7";
export var cardContainer = "styles-module--cardContainer--QFLs+";
export var cardContainerSec2 = "styles-module--cardContainerSec2--KmU5T";
export var cardContent = "styles-module--cardContent--ntzdy";
export var cardContentSec2 = "styles-module--cardContentSec2--c7ZOo";
export var cardDescriptionSec3 = "styles-module--cardDescriptionSec3--jFdKJ";
export var cardFashionWrapper = "styles-module--cardFashionWrapper--L7s54";
export var cardImage = "styles-module--cardImage--FpXbV";
export var cardImageSec2 = "styles-module--cardImageSec2--J2KD9";
export var cardImageSec4 = "styles-module--cardImageSec4--S1H8M";
export var cardImageSec6 = "styles-module--cardImageSec6--hwK0b";
export var cardImageSec6b = "styles-module--cardImageSec6b--a0I11";
export var cardImageWrapper = "styles-module--cardImageWrapper--+0L5G";
export var cardSec3 = "styles-module--cardSec3--5x0kZ";
export var contactButton = "styles-module--contactButton--9AfQA";
export var descriptionSec3 = "styles-module--descriptionSec3--19KC8";
export var infoTitleSec3 = "styles-module--infoTitleSec3--eBzUH";
export var logoSec3Wrapper = "styles-module--logoSec3Wrapper--aRhPn";
export var roadmapBackground = "styles-module--roadmapBackground--MmPGS";
export var row = "styles-module--row--ueJp0";
export var rowContent = "styles-module--rowContent--3sbEj";
export var section3 = "styles-module--section3--eJr37";
export var section4Image = "styles-module--section4Image--OBJxF";
export var sectionTitle = "styles-module--sectionTitle--7UHe6";
export var subSection4Background = "styles-module--subSection4Background--EqvdY";
export var subTitleSec3 = "styles-module--subTitleSec3--VBoP2";
export var subTitleSec4 = "styles-module--subTitleSec4--4PMk9";
export var titleSec3 = "styles-module--titleSec3--7lIY3";
export var titleSec3Wrapper = "styles-module--titleSec3Wrapper--2bklP";
export var titleSec4 = "styles-module--titleSec4--VCOhl";
export var titleSec4Wrapper = "styles-module--titleSec4Wrapper--hXQCL";
export var uppercaseWord = "styles-module--uppercaseWord--JLRNa";